


















































import Vue from 'vue';
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import {
  useLoadJobValidationApi,
  doesValidationHaveError,
  fixerFormManager,
  FixerBase,
  FixerNameValue,
  FixerFormParams,
  FixerValidationEvent
} from '@/module/api/load-job/validation';
import { useDomoApi } from '@/module/api/domo';

const { getItems: getDomos } = useDomoApi();

const {
  selectedItem: selectedValidation,
  selectItem: selectValidation,
  getItem: getValidation,
  isFixInProgress
} = useLoadJobValidationApi();

export class SetCallWrapupReasonParams {
  reasonCodeName!: string;
}

export class ReasonCodeOption {
  text!: string;
  value!: string;
}

const emptyState = () => {
  return {
    dialog: false,
    isValidForm: false,
    isLoading: false,
    formData: {
      reasonCodeName: ''
    } as SetCallWrapupReasonParams
  };
};
const state = reactive(emptyState());

const reasonCodeOptions = reactive({
  domoSetUuid: '',
  reasonCodes: [] as ReasonCodeOption[]
});

const reset = () => {
  Object.assign(state, emptyState());
};

const initializeData = async (params: { validationUuid: string; jobUuid: string }) => {
  if (!params.validationUuid || !params.jobUuid) {
    const errMessage = 'Cannot select validation without a validation uuid';
    Vue.$log.error(errMessage);
    return;
  }
  Vue.$log.debug('validationFormRename.initializeData');
  if (selectedValidation.value?.uuid !== params.validationUuid) {
    state.isLoading = true;
    await getValidation({ validationUuid: params.validationUuid, jobUuid: params.jobUuid });
    await selectValidation(params.validationUuid);
    if (reasonCodeOptions.domoSetUuid !== selectedValidation.domoSetUuid) {
      const domos = await getDomos({
        query: {
          'filter[domoSetUuid]': selectedValidation.value.domoSetUuid,
          'filter[entityType]': 'REASON_CODE',
          'filter[domb.type]': 'NOT_READY',
          // TODO: implement server side filtering on booleans
          // 'filter[domb.isSystem]': 'false',
          limit: '-1'
        }
      });
      if (!domos) {
        Vue.$log.error(
          `No reason codes found for domo set ${selectedValidation.value.domoSetUuid}`
        );
      } else {
        for (const domo of domos) {
          if (domo.domb?.isSystem) {
            continue;
          }
          reasonCodeOptions.reasonCodes.push({
            text: domo.dombName,
            value: domo.dombName
          });
        }
        reasonCodeOptions.domoSetUuid = selectedValidation.domoSetUuid;
      }
    }
    state.isLoading = false;
  }
};

export class FixerSetCallWrapupReason implements FixerBase {
  getName(): FixerNameValue {
    return FixerNameValue.SETCALLWRAPUPREASON;
  }

  async showForm(params: FixerFormParams): Promise<void> {
    state.dialog = true;
    await initializeData(params);
  }

  async hideForm(): Promise<void> {
    reset();
  }

  getValidationEvent(formData: any): FixerValidationEvent {
    const validationEvent: FixerValidationEvent = {
      name: this.getName(),
      userInputParams: {
        params: {
          reasonCodeName: state.formData.reasonCodeName
        }
      }
    };
    return validationEvent;
  }
}

export default defineComponent({
  name: 'FixerFormSetCallWrapupReason',
  emits: ['form:submit:validation'],
  props: { validationUuid: String },
  setup(props, context) {
    const fixer = new FixerSetCallWrapupReason();
    fixerFormManager.add(fixer);

    const emitSubmitForm = () => {
      context.emit('form:submit:validation', fixer.getValidationEvent(state.formData));
    };

    return {
      ...toRefs(state),
      reasonCodeOptions,
      emitSubmitForm,
      selectedValidation,
      doesValidationHaveError,
      isFixInProgress
    };
  }
});
