import { render, staticRenderFns } from "./FixerFormSetEmailSettings.vue?vue&type=template&id=3b24ef94&"
import script from "./FixerFormSetEmailSettings.vue?vue&type=script&lang=ts&"
export * from "./FixerFormSetEmailSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardText,VCardTitle,VCheckbox,VContainer,VDialog,VForm,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3b24ef94')) {
      api.createRecord('3b24ef94', component.options)
    } else {
      api.reload('3b24ef94', component.options)
    }
    module.hot.accept("./FixerFormSetEmailSettings.vue?vue&type=template&id=3b24ef94&", function () {
      api.rerender('3b24ef94', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/app/import/fixer/FixerFormSetEmailSettings.vue"
export default component.exports