























































































































































import Vue from 'vue';
import { defineComponent, reactive, toRefs, computed, ComputedRef } from '@vue/composition-api';
import { formatDbDate } from '@/utils';
import {
  useLoadJobValidationApi,
  LoadJobValidationModel,
  LoadJobValidationStateName,
  doesValidationHaveError,
  fixerFormManager,
  FixerValidationEvent
} from '@/module/api/load-job/validation';
import { useLoadJobApi, getFriendlyLoadJobTypeName } from '@/module/api/load-job';
import { useDomainApi } from '@/module/api/domain';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import Confirm, { useConfirmation } from '@/components/confirmation/Confirmation.vue';
import { createToastInterface } from 'vue-toastification';

// ====================================================================
// Fixer forms setup.
import FixerFormUserRename from '@/views/app/import/fixer/FixerFormUserRename.vue';
import FixerFormSetCallWrapupReason from '@/views/app/import/fixer/FixerFormSetCallWrapupReason.vue';
import FixerFormRename from '@/views/app/import/fixer/FixerFormRename.vue';
import FixerFormSetEmailSettings from '@/views/app/import/fixer/FixerFormSetEmailSettings.vue';
import FixerFormConvertNonE164ToE164 from '@/views/app/import/fixer/FixerFormConvertNonE164ToE164.vue';
import FixerFormInboundCampaignSetMaxLinesTo1IfGt from '@/views/app/import/fixer/FixerFormInboundCampaignSetMaxLinesTo1IfGt.vue';
import FixerFormUserMediaTypeDisableChat from '@/views/app/import/fixer/FixerFormUserMediaTypeDisableChat.vue';
import FixerFormUserMediaTypeDisableEmail from '@/views/app/import/fixer/FixerFormUserMediaTypeDisableEmail.vue';
import FixerFormUserMediaTypeDisableSocial from '@/views/app/import/fixer/FixerFormUserMediaTypeDisableSocial.vue';
import FixerFormUserAdminPermissionRemoveBilling from '@/views/app/import/fixer/FixerFormUserAdminPermissionRemoveBilling.vue';
import FixerFormUserMaxAllowedOneIfZero from '@/views/app/import/fixer/FixerFormUserMaxAllowedOneIfZero.vue';

const { selectedItem: selectedLoadJob, refreshItem: refreshLoadJob } = useLoadJobApi();

const {
  getItems: getValidations,
  getItem: getValidation,
  items: validations,
  selectedItem: selectedValidation,
  isLoading: isValidationLoading,
  isFixInProgress,
  applyFix
} = useLoadJobValidationApi();

const { selectedItem: selectedDomain, refreshItem: refreshDomain } = useDomainApi();

const state = reactive({
  isExportRunning: false,
  domainUuid: '',
  loadJobUuid: '',
  dialog: false,
  showOnlyFixable: false,
  showSnackbar: false,
  snackbarText: 'Fixer for this item is not currently implemented.'
});

const toast = createToastInterface({ maxToasts: 3 });

const validationSeverityMap: Record<string, Record<string, string>> = {
  error: {
    title: 'Error: this will prevent operations from succeeding.',
    icon: 'alert-octagon',
    color: 'error'
  },
  warning: {
    title: 'Warning: the operation may succeed, but require manual intervention.',
    icon: 'alert-outline',
    color: 'warning'
  },
  notice: {
    title: 'Notice: human judgement must be applied to determine severity.',
    icon: 'information-outline',
    color: 'info'
  }
};

const validationsList: ComputedRef<LoadJobValidationModel[]> = computed(() => {
  return validations.value;
});

const validationsByGroup = computed(() => {
  return validations.value?.reduce((validationsByGroup: any, current: LoadJobValidationModel) => {
    if (!Array.isArray(validationsByGroup[`${current.group}`])) {
      validationsByGroup[`${current.group}`] = [];
    }
    validationsByGroup[`${current.group}`].push(current);
    return validationsByGroup;
  }, {});
});

const initializeData = async (forceRefresh = false) => {
  await refreshDomain(state.domainUuid, forceRefresh);
  await refreshLoadJob(state.loadJobUuid, forceRefresh);
  getValidations({ uuid: state.loadJobUuid });
};

const getValidationsInGroup = (group: string): LoadJobValidationModel[] => {
  return validations.value?.filter((item: LoadJobValidationModel) => {
    return item.group === group;
  });
};

const refreshItems = async () => {
  initializeData(true);
};

const submitValidation = async (event: FixerValidationEvent) => {
  try {
    const errorMessage = await applyFix(
      selectedLoadJob.value?.uuid,
      selectedValidation.value,
      event.userInputParams
    );
    if (errorMessage !== undefined) {
      toast.error(errorMessage);
      return;
    }
    await fixerFormManager.hideForm(event.name);
    toast.success('Successfully applied fix to import job.');
  } catch (err) {
    const errMessage = 'Unable to add validation';
    Vue.$log.error(errMessage, err);
    toast.error(errMessage);
    return;
  }
};

const openFixFormForValidation = async (validationItem: LoadJobValidationModel) => {
  if (!validationItem || validationItem.fixerName === undefined) {
    const errMessage = 'Unable to open validation form for item';
    Vue.$log.error(errMessage);
    return;
  }
  fixerFormManager.showForm(validationItem.fixerName, {
    validationUuid: validationItem.uuid,
    jobUuid: selectedLoadJob.value?.uuid
  });
};

export default defineComponent({
  name: 'WorkspaceImportJobDetailFix',
  components: {
    RefreshButton,
    DomainWorkspaceToolbar,
    FixerFormUserRename,
    FixerFormSetCallWrapupReason,
    FixerFormRename,
    FixerFormSetEmailSettings,
    FixerFormConvertNonE164ToE164,
    FixerFormInboundCampaignSetMaxLinesTo1IfGt,
    FixerFormUserMediaTypeDisableChat,
    FixerFormUserMediaTypeDisableEmail,
    FixerFormUserMediaTypeDisableSocial,
    FixerFormUserAdminPermissionRemoveBilling,
    FixerFormUserMaxAllowedOneIfZero
  },
  props: {
    uuid: {
      type: String,
      default: ''
    },
    jobUuid: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    state.domainUuid = props.uuid;
    state.loadJobUuid = props.jobUuid;
    initializeData();

    return {
      ...toRefs(state),
      validationsList,
      validationsByGroup,
      getValidationsInGroup,
      formatDbDate,
      isValidationLoading,
      isFixInProgress,
      refreshItems,
      LoadJobValidationStateName,
      getFriendlyLoadJobTypeName,
      doesValidationHaveError,
      selectedLoadJob,
      selectedValidation,
      submitValidation,
      backLink: `/domain/${props.uuid}/job/import/${props.jobUuid}`,
      validationSeverityMap,
      openFixFormForValidation,
      validationsMenu: [
        {
          title: 'Re-validate',
          link: ''
        },
        { title: 'Validation History', link: '' },
        { title: 'Download as Spreadsheet', link: '' },
        { title: 'Configure', link: '' }
      ]
    };
  }
});
