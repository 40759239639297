














































import Vue from 'vue';
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import {
  useLoadJobValidationApi,
  doesValidationHaveError,
  fixerFormManager,
  FixerBase,
  FixerNameValue,
  FixerFormParams,
  FixerValidationEvent
} from '@/module/api/load-job/validation';

const {
  selectedItem: selectedValidation,
  selectItem: selectValidation,
  getItem: getValidation,
  isLoading: isValidationLoading,
  isFixInProgress
} = useLoadJobValidationApi();

export class RenameInput {
  name!: string;
  uuid!: string;
  originalName!: string;
  entityType!: string;
}
export class RenameUserInput {
  renames!: RenameInput[];
}

const emptyState = () => {
  return {
    dialog: false,
    isValidForm: false,
    isLoading: false,
    formData: {
      renames: []
    } as RenameUserInput
  };
};
const state = reactive(emptyState());

const reset = () => {
  Object.assign(state, emptyState());
};

const initializeData = async (params: { validationUuid: string; jobUuid: string }) => {
  if (!params.validationUuid || !params.jobUuid) {
    const errMessage = 'Cannot select validation without a validation uuid';
    Vue.$log.error(errMessage);
    return;
  }
  Vue.$log.debug('validationFormRename.initializeData');
  await selectValidation(params.validationUuid);
  if (selectedValidation.value?.uuid !== params.validationUuid) {
    await getValidation({ validationUuid: params.validationUuid, jobUuid: params.jobUuid });
    await selectValidation(params.validationUuid);
  }
  state.formData.renames = [];
  for (const ref of selectedValidation.value.refs) {
    state.formData.renames.push({
      originalName: ref.name,
      name: ref.name,
      uuid: ref.uuid,
      entityType: ref.entityType
    });
  }
};

export class FixerRename implements FixerBase {
  getName(): FixerNameValue {
    return FixerNameValue.RENAME;
  }

  async showForm(params: FixerFormParams): Promise<void> {
    await initializeData(params);
    state.dialog = true;
  }

  async hideForm(): Promise<void> {
    reset();
  }

  getValidationEvent(formData: any): FixerValidationEvent {
    const validationEvent: FixerValidationEvent = {
      name: this.getName(),
      userInputParams: {
        params: {
          renames: []
        }
      }
    };

    // Type guard because why.
    if (validationEvent.userInputParams?.params === undefined) {
      return validationEvent;
    }

    for (const renameInput of state.formData.renames) {
      validationEvent.userInputParams.params.renames.push({
        name: renameInput.name,
        uuid: renameInput.uuid
      });
    }

    return validationEvent;
  }
}

export default defineComponent({
  name: 'FixerFormRename',
  emits: ['form:submit:validation'],
  props: { validationUuid: String },
  setup(props, context) {
    const fixer = new FixerRename();
    fixerFormManager.add(fixer);

    const emitSubmitForm = () => {
      context.emit('form:submit:validation', fixer.getValidationEvent(state.formData));
    };

    return {
      ...toRefs(state),
      emitSubmitForm,
      selectedValidation,
      isValidationLoading,
      doesValidationHaveError,
      isFixInProgress
    };
  }
});
