import { render, staticRenderFns } from "./FixerFormUserRename.vue?vue&type=template&id=0c5b5520&"
import script from "./FixerFormUserRename.vue?vue&type=script&lang=ts&"
export * from "./FixerFormUserRename.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardText,VCardTitle,VContainer,VDialog,VForm,VSpacer,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0c5b5520')) {
      api.createRecord('0c5b5520', component.options)
    } else {
      api.reload('0c5b5520', component.options)
    }
    module.hot.accept("./FixerFormUserRename.vue?vue&type=template&id=0c5b5520&", function () {
      api.rerender('0c5b5520', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/app/import/fixer/FixerFormUserRename.vue"
export default component.exports