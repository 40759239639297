var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600px" },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { attrs: { "max-height": "750px", loading: _vm.isLoading } },
        [
          _c("v-card-title", [
            _c("span", { staticClass: "text-h5" }, [_vm._v("Rename Usernames")])
          ]),
          _c(
            "v-card-text",
            [
              _c("p", [_vm._v(_vm._s(_vm.selectedValidation.validationLabel))]),
              _c("p", [
                _vm._v(" Usernames will be renamed like: "),
                _c("code", [
                  _vm._v(
                    "userName" +
                      _vm._s(_vm.formData.constantSuffix) +
                      _vm._s(_vm.randomSuffixMask)
                  )
                ])
              ]),
              _c(
                "v-alert",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.doesValidationHaveError(
                        _vm.selectedValidation
                      ),
                      expression: "doesValidationHaveError(selectedValidation)"
                    }
                  ],
                  staticClass: "elevation-2",
                  attrs: { type: "error" }
                },
                [_vm._v(" " + _vm._s(_vm.selectedValidation.errorLabel) + " ")]
              ),
              _c(
                "v-container",
                [
                  _c(
                    "v-form",
                    {
                      attrs: { disabled: _vm.isLoading || _vm.isFixInProgress },
                      model: {
                        value: _vm.isValidForm,
                        callback: function($$v) {
                          _vm.isValidForm = $$v
                        },
                        expression: "isValidForm"
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: { label: "Static suffix" },
                        model: {
                          value: _vm.formData.constantSuffix,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "constantSuffix", $$v)
                          },
                          expression: "formData.constantSuffix"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          label: "Random suffix length",
                          type: "number"
                        },
                        model: {
                          value: _vm.formData.randomSuffixLength,
                          callback: function($$v) {
                            _vm.$set(_vm.formData, "randomSuffixLength", $$v)
                          },
                          expression: "formData.randomSuffixLength"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isFixInProgress,
                  expression: "isFixInProgress"
                }
              ]
            },
            [
              _vm._v(
                " A fix is currently in progress. You may not apply fixes until that finishes. "
              )
            ]
          ),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "error",
                    text: "",
                    disabled: _vm.isFixInProgress
                  },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "primary", disabled: _vm.isFixInProgress },
                  on: { click: _vm.emitSubmitForm }
                },
                [_vm._v(" Apply Fix ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }