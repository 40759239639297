









































import Vue from 'vue';
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import {
  useLoadJobValidationApi,
  FixerUserInputParams,
  doesValidationHaveError,
  TransformerIdValue,
  UserNameTransformerParamsType,
  FixerBase,
  FixerNameValue,
  FixerFormParams,
  fixerFormManager,
  FixerValidationEvent
} from '@/module/api/load-job/validation';

const {
  selectedItem: selectedValidation,
  selectItem: selectValidation,
  getItem: getValidation,
  isLoading: isValidationLoading,
  isFixInProgress
} = useLoadJobValidationApi();

const emptyState = () => {
  return {
    dialog: false,
    isValidForm: false,
    isLoading: false
  };
};
const state = reactive(emptyState());

const formData = reactive({
  constantSuffix: '_',
  randomSuffixLength: 4
});

const reset = () => {
  Object.assign(state, emptyState());
};

const initializeData = async (params: { validationUuid: string; jobUuid: string }) => {
  if (!params.validationUuid || !params.jobUuid) {
    const errMessage = 'Cannot select validation without a validation uuid';
    Vue.$log.error(errMessage);
    return;
  }
  Vue.$log.debug('validationFormUsers.initializeData');
  await selectValidation(params.validationUuid);
  if (selectedValidation.value?.uuid !== params.validationUuid) {
    await getValidation({ validationUuid: params.validationUuid, jobUuid: params.jobUuid });
    await selectValidation(params.validationUuid);
  }
};

export class FixerRename implements FixerBase {
  getName(): FixerNameValue {
    return FixerNameValue.USERNAME;
  }

  async showForm(params: FixerFormParams): Promise<void> {
    initializeData(params);
    state.dialog = true;
  }

  async hideForm(): Promise<void> {
    reset();
  }

  getValidationEvent(formData: any): FixerValidationEvent {
    const validationEvent: FixerValidationEvent = {
      name: this.getName(),
      userInputParams: undefined
    };
    const fixerUserInputParams: FixerUserInputParams = {
      params: {
        operations: []
      }
    };
    // Do a type guard here because typescript, wtf.
    if (
      fixerUserInputParams.params === undefined ||
      fixerUserInputParams.params.operations === undefined
    ) {
      return validationEvent;
    }
    if (formData.constantSuffix) {
      fixerUserInputParams.params.operations.push({
        transformerId: TransformerIdValue.USER_NAME_TRANSFORMER,
        type: UserNameTransformerParamsType.SUFFIX,
        suffix: formData.constantSuffix
      });
    }
    if (formData.randomSuffixLength) {
      fixerUserInputParams.params.operations.push({
        transformerId: TransformerIdValue.USER_NAME_TRANSFORMER,
        type: UserNameTransformerParamsType.RANDOM_SUFFIX,
        length: Number(formData.randomSuffixLength)
      });
    }
    validationEvent.userInputParams = fixerUserInputParams;
    return validationEvent;
  }
}

export default defineComponent({
  name: 'FixerFormUserRename',
  emits: ['form:submit:validation'],
  props: ['validationUuid'],
  computed: {
    randomSuffixMask: function randomSuffixMask() {
      return 'X'.repeat(formData.randomSuffixLength);
    }
  },
  setup(props, context) {
    const fixer = new FixerRename();
    fixerFormManager.add(fixer);

    const emitSubmitForm = () => {
      context.emit('form:submit:validation', fixer.getValidationEvent(formData));
    };

    return {
      ...toRefs(state),
      emitSubmitForm,
      selectedValidation,
      isValidationLoading,
      doesValidationHaveError,
      isFixInProgress,
      formData
    };
  }
});
