





































import Vue from 'vue';
import { defineComponent, reactive, toRefs } from '@vue/composition-api';
import {
  useLoadJobValidationApi,
  doesValidationHaveError,
  FixerBase,
  FixerNameValue,
  FixerFormParams,
  fixerFormManager,
  FixerValidationEvent
} from '@/module/api/load-job/validation';
import FixerFormUserMaxAllowedZeroIfOneVue from './FixerFormUserMaxAllowedZeroIfOne.vue';

const {
  selectedItem: selectedValidation,
  selectItem: selectValidation,
  getItem: getValidation,
  isLoading: isValidationLoading,
  isFixInProgress
} = useLoadJobValidationApi();

const emptyState = () => {
  return {
    dialog: false,
    isValidForm: false,
    isLoading: false
  };
};
const state = reactive(emptyState());

const formData = reactive({
  disableUserEmailNotifications: true,
  clearAllEmailNotificationAddresses: true
});

const reset = () => {
  Object.assign(state, emptyState());
};

const initializeData = async (params: { validationUuid: string; jobUuid: string }) => {
  if (!params.validationUuid || !params.jobUuid) {
    const errMessage = 'Cannot select validation without a validation uuid';
    Vue.$log.error(errMessage);
    return;
  }
  Vue.$log.debug('FixerFormUserMaxAllowedOneIfZero.initializeData');
  await selectValidation(params.validationUuid);
  if (selectedValidation.value?.uuid !== params.validationUuid) {
    await getValidation({ validationUuid: params.validationUuid, jobUuid: params.jobUuid });
    await selectValidation(params.validationUuid);
  }
};

export class FixerFormUserSetMaxAllowedOneIfZero implements FixerBase {
  getName(): FixerNameValue {
    return FixerNameValue.USERSETMAXALLOWEDONEIFZERO;
  }

  async showForm(params: FixerFormParams): Promise<void> {
    initializeData(params);
    state.dialog = true;
  }

  async hideForm(): Promise<void> {
    reset();
  }

  getValidationEvent(formData: any): FixerValidationEvent {
    const validationEvent: FixerValidationEvent = {
      name: this.getName(),
      userInputParams: {
        params: {}
      }
    };
    return validationEvent;
  }
}

export default defineComponent({
  name: 'FixerFormUserMaxAllowedZeroIfOne',
  emits: ['form:submit:validation'],
  props: ['validationUuid'],
  setup(props, context) {
    const fixer = new FixerFormUserSetMaxAllowedOneIfZero();
    fixerFormManager.add(fixer);

    const emitSubmitForm = () => {
      context.emit('form:submit:validation', fixer.getValidationEvent(formData));
    };

    return {
      ...toRefs(state),
      emitSubmitForm,
      selectedValidation,
      isValidationLoading,
      doesValidationHaveError,
      isFixInProgress,
      formData
    };
  }
});
