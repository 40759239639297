var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: {
                  backLink: _vm.backLink,
                  subtitle:
                    _vm.getFriendlyLoadJobTypeName(_vm.selectedLoadJob.type) +
                    " Job",
                  subsubtitle: "Fix Job"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "", "fill-height": "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("p", [
                    _vm._v(
                      " Issues are grouped below according to their type. Download the Validation Report to see a full list of issues. "
                    )
                  ]),
                  _c("v-checkbox", {
                    attrs: { label: "Only show fixable validations." },
                    on: {
                      click: function($event) {
                        _vm.showOnlyFixable = !_vm.showOnlyFixable
                      }
                    }
                  }),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.validationsList.length < 1 &&
                            _vm.isValidationLoading,
                          expression:
                            "validationsList.length < 1 && isValidationLoading"
                        }
                      ]
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", color: "primary" }
                      }),
                      _vm._v(" Issues are loading... ")
                    ],
                    1
                  ),
                  _c("v-data-iterator", {
                    attrs: {
                      items: _vm.validationsList,
                      "item-key": "sectionGroup",
                      "hide-default-footer": "",
                      "disable-pagination": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c(
                              "v-expansion-panels",
                              {
                                staticClass: "mb-4",
                                attrs: {
                                  multiple: "",
                                  value: Array.from(
                                    Array(_vm.validationsList.length).keys()
                                  )
                                }
                              },
                              _vm._l(
                                Object.keys(_vm.validationsByGroup),
                                function(group, i) {
                                  return _c(
                                    "v-expansion-panel",
                                    { key: i },
                                    [
                                      _c("v-expansion-panel-header", [
                                        _vm._v(
                                          " " +
                                            _vm._s(group) +
                                            " (" +
                                            _vm._s(
                                              Array.isArray(
                                                _vm.validationsByGroup[group]
                                              )
                                                ? _vm.validationsByGroup[group]
                                                    .length
                                                : ""
                                            ) +
                                            ") "
                                        )
                                      ]),
                                      _c(
                                        "v-expansion-panel-content",
                                        [
                                          _c(
                                            "v-list",
                                            {
                                              attrs: {
                                                flat: "",
                                                "two-line": ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-group",
                                                {
                                                  attrs: {
                                                    multiple: "",
                                                    "active-class": ""
                                                  }
                                                },
                                                _vm._l(
                                                  _vm.getValidationsInGroup(
                                                    group
                                                  ),
                                                  function(validationItem, j) {
                                                    return _c(
                                                      "v-list-item",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.showOnlyFixable ===
                                                                false ||
                                                              validationItem.isFixable ===
                                                                true,
                                                            expression:
                                                              "showOnlyFixable === false || validationItem.isFixable === true"
                                                          }
                                                        ],
                                                        key: j
                                                      },
                                                      [
                                                        _c(
                                                          "v-list-item-action",
                                                          [
                                                            validationItem.isFixable ===
                                                            false
                                                              ? _c(
                                                                  "span",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            "",
                                                                          width:
                                                                            "6em"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "NA"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : validationItem.state ===
                                                                _vm
                                                                  .LoadJobValidationStateName
                                                                  .INVALID
                                                              ? _c(
                                                                  "span",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          color:
                                                                            "primary",
                                                                          width:
                                                                            "6em",
                                                                          disabled:
                                                                            _vm.isFixInProgress
                                                                        },
                                                                        on: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.openFixFormForValidation(
                                                                              validationItem
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm.doesValidationHaveError(
                                                                          validationItem
                                                                        )
                                                                          ? _c(
                                                                              "v-badge",
                                                                              {
                                                                                attrs: {
                                                                                  dot:
                                                                                    "",
                                                                                  color:
                                                                                    "error"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.openFixFormForValidation(
                                                                                      validationItem
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " Fix "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _c(
                                                                              "span",
                                                                              [
                                                                                _vm._v(
                                                                                  " Fix "
                                                                                )
                                                                              ]
                                                                            )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : validationItem.state ===
                                                                _vm
                                                                  .LoadJobValidationStateName
                                                                  .VALID
                                                              ? _c(
                                                                  "span",
                                                                  [
                                                                    _c(
                                                                      "v-btn",
                                                                      {
                                                                        attrs: {
                                                                          disabled:
                                                                            "",
                                                                          width:
                                                                            "6em"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Fixed"
                                                                        )
                                                                      ]
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-list-item-action-text",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-4",
                                                                attrs: {
                                                                  color:
                                                                    _vm
                                                                      .validationSeverityMap[
                                                                      validationItem
                                                                        .severity
                                                                    ].color,
                                                                  title:
                                                                    _vm
                                                                      .validationSeverityMap[
                                                                      validationItem
                                                                        .severity
                                                                    ].title
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-" +
                                                                    _vm._s(
                                                                      _vm
                                                                        .validationSeverityMap[
                                                                        validationItem
                                                                          .severity
                                                                      ].icon
                                                                    )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "v-list-item-content",
                                                          [
                                                            _c(
                                                              "v-list-item-title",
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    validationItem.validationLabel
                                                                  ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      validationItem.count
                                                                    ) +
                                                                    ")"
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "v-list-item-subtitle",
                                                              {
                                                                staticStyle: {
                                                                  "white-space":
                                                                    "normal"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    validationItem.fixLabel
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              ),
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("fixer-form-rename", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-set-call-wrapup-reason", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-user-rename", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-set-email-settings", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-convert-non-e164-to-e164", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-inbound-campaign-set-max-lines-to1-if-gt", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-user-media-type-disable-chat", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-user-media-type-disable-email", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-user-media-type-disable-social", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-user-admin-permission-remove-billing", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c("fixer-form-user-max-allowed-one-if-zero", {
        on: { "form:submit:validation": _vm.submitValidation }
      }),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 3000 },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function(ref) {
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: { color: "pink", text: "" },
                        on: {
                          click: function($event) {
                            _vm.showSnackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v(" Close ")]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.showSnackbar,
            callback: function($$v) {
              _vm.showSnackbar = $$v
            },
            expression: "showSnackbar"
          }
        },
        [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }